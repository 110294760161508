body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Adjust icon container size */
  height: 50px;
  border-radius: 50%;
  background-color: white; /* Set background to enhance shadow visibility */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Black shadow with 20% opacity */
  transition: box-shadow 0.2s ease-in-out; /* Smooth transition for hover */
}

.icon-container:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4); /* Darker shadow on hover */
}
