.card-nav-links {
  display: flex;
  flex-direction: row; 
  gap: 10px;
  padding: 10px; 
  background-color: white; 
  border: 1px solid #ddd; 
  border-radius: 4px; 
  margin-bottom: 20px; 
}

.card-nav-links a {
  display: block;
  padding: 10px 15px;
  text-align: center;
  color: black; 
  text-decoration: none; 
  border-radius: 4px;
}

.card-nav-links a:hover,
.card-nav-links a.is-selected {
  background-color: #3273dc; /* Bulma's primary color */
  color: white; /* Ensure text is readable on the primary color background */
}

@keyframes moveUp {
  from {
    transform: translateY(300px);
  }
  to {
    transform: translateY(0);
  }
}

.card-move-up {
  animation: moveUp 1s ease-out forwards;
}



.media-item-container {
  display: flex;
  flex-direction: row-reverse; /* Image on right, data on left */
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
}

.media-image {
  flex: 1;
  display: flex;
  justify-content: flex-start; /* Align image to the top */
  align-items: flex-start; /* Align image to the top */
  padding: 20px; /* Optional: Add some padding to separate image from the edges */
}

.media-image img {
  max-width: 100%;
  height: auto;
  border-left: 1px solid #eaeaea;
}

.media-data-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
}

.media-data-content .content {
  max-width: 400px; /* Adjust as needed */
}
/* Increase the size of the profile image */
.profile-card .image.is-128x128 {
  width: 300px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
}

.profile-card .image.is-128x128 img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}


/* UserDetailPage.css
.container {
  margin: 20px;
}

.card {
  margin-bottom: 20px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-nav-links {
  display: flex;
  justify-content: space-around;
}

.card-nav-links a {
  padding: 10px;
  text-decoration: none;
  color: #3273dc;
}

.card-nav-links a.is-selected {
  font-weight: bold;
  color: #ff3860;
}

.columns {
  margin-bottom: 20px;
}

.column {
  margin-bottom: 20px;
}

.title.is-6 {
  font-size: 1.25rem;
}

.subtitle.is-5 {
  font-size: 1.125rem;
} */




/* UserDetailPage.css */
/* .card.profile-card {
  margin-bottom: 20px;
} */

/* .card-nav-links {
  display: flex;
  flex-direction: column;
}

.card-nav-links a {
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.card-nav-links a.is-selected {
  background-color: #007bff;
  color: #fff;
}

.section {
  padding: 20px;
} */
